<template>
  <div :class="this.accordianClass">
    <button @click="isOpen = !isOpen">{{ this.title }}</button>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccordianView',
  props: {
    title: {
      type: String,
      required: false
    },
    openByDefault: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    this.isOpen = this.openByDefault;
  },
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    accordianClass() {
      return this.isOpen ? 'accordian active' : 'accordian';
    }
  }
};
</script>

<style scoped>
button {
  background: none;
  border: none;
  padding: 0;
  color: var(--grey-900);
  text-transform: uppercase;
  text-align: left;
  font: normal normal bold 1rem/1.25rem Inter;
  transition: all 0.2s ease-out;
}

button:focus {
  box-shadow: none;
}

button:before {
  display: inline-block;
  margin: 0 0.5rem;
  content: url('~@/assets/svg/wedge.svg');
  transition: all 0.2s ease-out;
}

.active button::before {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.content {
  max-height: 0;
  overflow: hidden;
  margin-top: 0.875rem;
  opacity: 0;
}

.active .content {
  max-height: 100%;
  opacity: 1;
}
</style>